
<!-- NAV -->
<nav id="mynav" class="navbar navbar-expand-xl fixed-top navbar-light cu-nav" >
    <a class="navbar-brand cu-nav-a" style="cursor: pointer;" (click)="goTo('inicioTop')"  >
        <img class="cu-logo" src="assets/img/nav-logo.png"  alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
        <span  class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse cu-nav-list"  id="navbarNav" style="height: 100px;">
        <ul class="navbar-nav ml-auto " style="height: 100%; ">
            <li id="liuso" class="nav-item cu-item ">
                <a id="auso" class="nav-link text-nav color-nav-1 "(click)="goTo('gouso')"   >¿Cómo se usa? </a>
            </li>
            <li id="libeneficio" class="nav-item cu-item" >
                <a id="abeneficio" class="nav-link text-nav color-nav-1 " (click)="goTo('gobenefit')" >Beneficios</a>
            </li>
            <li id="lidudas" class="nav-item cu-item">
                <a id="adudas" class="nav-link text-nav color-nav-1 " (click)="goTo('godudas')" >¿Tienes dudas?</a>
            </li>
            <li id="liobten" class="nav-item cu-item">
                <a id="aobten" class="nav-link text-nav color-nav-1 " (click)="goTo('goobten')"  >Obtén OK Boleta</a>
            </li>
            <li id="licontacto" class="nav-item cu-item">
                <a id="acontacto" class="nav-link text-nav color-nav-1 " (click)="goTo('gocontact')" >Contáctanos</a>
            </li>
        </ul>
    </div>
</nav>


<!-- INICIO -->
<p class="cu-inicio-margin" id="inicioTop"></p>
<section id="inicio" class="cu-inicio" >
<div class="row" style="width: 100%;">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 div-title text-center">
        <p class="text-title text-center">La manera 
            <strong style="font-family: 'Arial rounded mt' !important;">
            Fácil y Rápida</strong> de <br> emitir tu boleta electrónica</p>
        
        <div class="content-shape" style="width: 100%;">
            <div class="my-shape">
                <p class="text-shape">¿Es tu primer teléfono inteligente? <br>
                    esta app es para ti</p>
            </div>
            <div class="g-play">
                <img class="g-play-img" src="assets/img/google-play.png" (click)="goToLink('https://play.google.com/store/apps/developer?id=Foyke+Soft&hl=es_419&gl=US')" alt="">
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <img class="img-devices" src="assets/img/phones-large.png" alt="" >
    </div>
    
</div>

</section>

<!-- uso -->
<p class="cu-inicio-margin" id="gouso"></p>
<section id="uso">
    <div class="row" style="width: 100%;">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
            <div class="col text-center cu-uso">
                <p class="title-purp">¿Como se usa?</p>
            </div>
            <div class="content-rectangule" style="width: 100%;">
                <div class="cu-phone-1">
                    <img class="cu-phone-img-1" src="assets/img/phone-1.png" alt="">
                </div>
                <div class="my-rectangule">
                    <p class="text-rectangule">Ingresa tus datos asociados al SII</p>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
            <!-- <div class="cu-phone-2">
                <img class="cu-phone-img-2" src="assets/img/phone-2.png" alt="">
            </div> -->
            <div class="content-rectangule" style="width: 100%;">
                <div class="cu-phone-2">
                    <img class="cu-phone-img-2" src="assets/img/phone-2.png" alt="">
                </div>
                <div class="my-rectangule">
                    <p class="text-rectangule">Configura y
                        elige los parámetros que
                        se adecuan a tu negocio</p>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center offset-xl-0 offset-lg-0 offset-md-3 offset-sm-3 offset-0">
            <!-- <div class="cu-phone-3">
                <img class="cu-phone-img-3" src="assets/img/phone-3.png" alt="">
            </div> -->
            <div class="content-rectangule" style="width: 100%;">
                <div class="cu-phone-3">
                    <img class="cu-phone-img-3" src="assets/img/phone-3.png" alt="">
                </div>
                <div class="my-rectangule-3">
                    <p class="text-rectangule">y listo! <br>
                        ya puedes comenzar a usar
                        OK Boleta</p>
                </div>
            </div>
        </div>
        <div class="col-12" style="top: -50px;">
            <div class="row cu-row-obten">
                <div class="col text-center">
                    <p class="title-purp"> En solo 3 pasos <span><img class="cu-ok-obten" src="assets/img/ok-boleta.png" alt=""></span> esta lista para que la uses! <span><img class="cu-ok-obten" src="assets/img/play.png" alt=""></span></p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- beneficios -->
<p class="cu-inicio-margin" id="gobenefit"></p>
<section id="beneficios">
    <div class="row cu-uso" style="width: 100%;">
         <div class="col text-center ">
            <p class="title-purp">Beneficios</p>
        </div>
    </div>

    <div class="row" style="width: 100%;">
        <div class="col text-center cu-col-bene">
            <div class="content-shape-beneficio" >
                <img style="padding-left: 50px; padding-right: 50px;" src="assets/img/benefit-full.png" width="100%" alt="">

                <div class="my-shape-beneficio-title-1">
                    <p class="text-shape-beneficio-title">Fácil y Rápido</p>
                </div>
                <div class="my-shape-beneficio-1">
                    <p class="text-shape-beneficio">Con OK Boleta simplificamos la
                        emisión de Boletas, para que no
                        pierdas tiempo mientras vendes!</p>
                </div>

                <div class="my-shape-beneficio-title-2">
                    <p class="text-shape-beneficio-title">Sin Intermediarios</p>
                </div>

                <div class="my-shape-beneficio-2">
                    <p class="text-shape-beneficio">Emite Boletas sin intermediarios
                        Ok Boleta emite directo con el SII.</p>
                </div>

                <div class="my-shape-beneficio-title-3">
                    <p class="text-shape-beneficio-title-3">Diseño Inclusivo</p>
                </div>

                <div class="my-shape-beneficio-3">
                    <p class="text-shape-beneficio">En OK Boleta valoramos a las
                        personas mayores, por eso nuestra
                        aplicación esta diseñada para
                        que sea fácil de usar, ver y comprender.</p>
                </div>

                <div class="my-shape-beneficio-title-4">
                    <p class="text-shape-beneficio-title">Emisión Ilimitada</p>
                </div>
                <div class="my-shape-beneficio-4">
                    <p class="text-shape-beneficio">Emite todas las boletas que necesites,
                        con OK Boleta no pagas demás!</p>
                </div>


                <div class="my-shape-beneficio-title-5">
                    <p class="text-shape-beneficio-title">Control de Ventas</p>
                </div>
                <div class="my-shape-beneficio-5">
                    <p class="text-shape-beneficio">Ok Boleta te permite administrar tus
                        ventas diarias y mensuales.</p>
                </div>
            </div>
            
        </div>

        <div class="col text-center cu-col-bene-2">
            <div class="content-bene" style="width: 100%;">
                <div class="my-bene">
                    <img class="" src="assets/img/bene-1.png" style="width: 128px; height: 128px;" alt="">
                    <p class="text-bene-1"> Fácil y Rápido
                    </p>
                    <p class="text-bene">Con OK Boleta simplificamos la
                        emisión de Boletas, para que no
                        pierdas tiempo mientras vendes!
                    </p>

                    <img class="" src="assets/img/bene-2.png" style="width: 128px; height: 128px;" alt="">
                    <p class="text-bene-2"> Sin Intermediarios
                    </p>
                    <p class="text-bene">Emite Boletas sin intermediarios
                        Ok Boleta emite directo con el SII.
                    </p>

                    <img class="" src="assets/img/bene-3.png" style="width: 153px; height: 153px;" alt="">
                    <p class="text-bene-3"> Diseño inclusivo
                    </p>
                    <p class="text-bene">En OK Boleta valoramos a las
                        personas mayores, por eso nuestra
                        aplicación esta diseñada para
                        que sea fácil de usar, ver y comprender.
                    </p>

                    <img class="" src="assets/img/bene-4.png" style="width: 128px; height: 128px;" alt="">
                    <p class="text-bene-4"> Emisión ilimitada
                    </p>
                    <p class="text-bene">Emite todas las boletas que necesites,
                        con OK Boleta no pagas demás!
                    </p>

                    <img class="" src="assets/img/bene-5.png" style="width: 128px; height: 128px;" alt="">
                    <p class="text-bene-5"> Control de Ventas
                    </p>
                    <p class="text-bene">Ok Boleta te permite administrar tus
                        ventas diarias y mensuales.
                    </p>


                </div>
            </div>
        </div>
    </div>
</section>

<!-- dudas -->
<p class="cu-inicio-margin" ></p>
<p class="cu-inicio-margin" id="godudas"></p>
<section id="dudas">
    <div class="row cu-uso" style="width: 100%;">
        <div class="col text-center ">
           <p class="title-purp">¿Tienes dudas?</p>
       </div>
   </div>
    <div class="row cu-row-dudas">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="content-dudas" style="width: 100%;">
                <div class="my-dudas dudas-1">
                    <p class="text-dudas">Tiene costo?</p>
                    <div class="my-dudas-img dudas-img-1">
                        <img src="assets/img/ok-boleta.png" alt="">
                    </div>
                </div>
                
                <div class="my-dudas-resp dudas-resp-1" >
                    <p class="text-dudas-resp">Ok boleta no tiene costo, trabaja por medio de publicidad.</p>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="content-dudas" style="width: 100%;">
                <div class="my-dudas dudas-2" >
                    <p class="text-dudas">Tiene algún requisito?</p>
                    <div class="my-dudas-img dudas-img-2" >
                        <img src="assets/img/ok-boleta.png" alt="">
                    </div>
                </div>
                
                <div class="my-dudas-resp dudas-resp-2">
                    <p class="text-dudas-resp">Sí, debes estar habilitado para dar boletas mediante el SII (uno o más emisores registrados).</p>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="content-dudas" style="width: 100%;">
                <div class="my-dudas dudas-3" >
                    <p class="text-dudas">Se encuentra bajo las normas
                        del SII?</p>
                    <div class="my-dudas-img dudas-img-3">
                        <img src="assets/img/ok-boleta.png" alt="">
                    </div>
                </div>
                
                <div class="my-dudas-resp dudas-resp-3" >
                    <p class="text-dudas-resp">Sí, Ok Boleta trabaja directamente con el SII.</p>
                </div>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="content-dudas" style="width: 100%;">
                <div class="my-dudas dudas-4" >
                    <p class="text-dudas">Se puede eliminar la publicidad?</p>
                    <div class="my-dudas-img dudas-img-4">
                        <img src="assets/img/ok-boleta.png" alt="">
                    </div>
                </div>
                
                <div class="my-dudas-resp dudas-resp-4" >
                    <p class="text-dudas-resp">Sí, para ello deberás asumir un costo mensual u anual.</p>
                </div>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="content-dudas" style="width: 100%;">
                <div class="my-dudas dudas-5" >
                    <p class="text-dudas">Tiene soporte?</p>
                    <div class="my-dudas-img dudas-img-5">
                        <img src="assets/img/ok-boleta.png" alt="">
                    </div>
                </div>
                
                <div class="my-dudas-resp dudas-resp-5">
                    <p class="text-dudas-resp">Sí, puedes comunicarte mediante los medios disponibles, visita la sección contacto</p>
                </div>
            </div>
        </div>
        

    </div>
</section>

<!-- OBTEN -->
<p class="cu-inicio-margin" ></p>
<p class="cu-inicio-margin" id="goobten"></p>
<section class="cu-obten" id="obten">
    <div class="row" style="width: 100%;">
        <div class="col">
            <div class="row cu-row-obten">
                <div class="col text-center">
                    <p class="title-purp"> Descarga ahora tu <span><img class="cu-ok-obten" src="assets/img/ok-boleta.png" alt=""></span> de acuerdo al equipo que tengas</p>
                </div>
            </div>
            <div class="row cu-row-obten-imgs">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                    <div class="cu-version-1">
                        <img class="cu-version-img-1" src="assets/img/q2.png" alt="">
                    </div>
                    <div class="content-download" style="width: 100%;">
                        <div class="my-download" (click)="goToLink('https://play.google.com/store/apps/details?id=com.foyke.okboleta.Q2&hl=es_419&gl=US')">
                            <p class="text-download">Modelo Q2/Q2S</p>
                            <div class="img-download">
                                <img  src="assets/img/download.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
                    <div class="cu-version-2">
                        <img class="cu-version-img-2" src="assets/img/t1n.png" alt="">
                    </div>
                    <div class="content-download" style="width: 100%;">
                        <div class="my-download" (click)="goToLink('https://play.google.com/store/apps/details?id=com.foyke.okboleta&hl=es_419&gl=US')">
                            <p class="text-download">Modelo T1N</p>
                            <div class="img-download">
                                <img  src="assets/img/download.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12  offset-xl-0 offset-lg-3 offset-md-3 offset-sm-3 offset-0 text-center">
                    <div class="cu-version-3">
                        <img class="cu-version-img-3" src="assets/img/android.png" alt="">
                    </div>
                    <div class="content-download" style="width: 100%;">
                        <div class="my-download" (click)="goToLink('https://play.google.com/store/apps/details?id=com.foyke.android.okboleta&hl=es_419&gl=US')">
                            <p class="text-download">Android</p>
                            <div class="img-download">
                                <img  src="assets/img/download.png" alt="">
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>



<!-- CONTACTO -->
<p class="cu-inicio-margin" id="gocontact"></p>
<section class="cu-contacto" id="contacto">
    <div class="row" style="width: 100%;">
        <div class="col">
            <div class="content-contact ">
                <div class="row">
                    <div class="col text-center">
                        <p class="title-purp">Contáctanos</p>
                    </div>
                </div>
        
                <form [formGroup]="contactoForm" (ngSubmit)="onSubmit(contactoForm.value, loading)" >
                            <div class="row cu-row-contact">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label class="label-contact" for="">NOMBRE</label>
                                <input class="input-contact" [ngStyle]="boolNombre ?  {'border': '1px solid #691AE5 !important'} : {'border': 'none'}" placeholder="Ingrese nombre" type="text" formControlName="nombre">
                
                                <label class="label-contact" for="">EMAIL</label>
                                <input class="input-contact" placeholder="Ingrese email" type="text" formControlName="email">
                
                                <label class="label-contact" for="">TELÉFONO</label>
                                <input class="input-contact" placeholder="Ingrese teléfono" type="text" formControlName="telefono">
                
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label class="label-contact" for="">MENSAJE</label>
                                <textarea class="input-textarea" placeholder="Ingrese mensaje" formControlName="message"></textarea>
                                <div class="float-right">
                
                                    <button type="submit"  class="btn-enviar">ENVIAR</button>
                                </div>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
    </div>
    

</section>



<footer class="cu-footer">
    <div class="row cu-row-footer">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <ul class="cu-ul">
                        <li class="cu-li" (click)="goTo('gouso')" >¿Cómo se usa?</li>
                        <li class="cu-li" (click)="goTo('gobenefit')"  >Beneficios</li>
                        <li class="cu-li" (click)="goTo('godudas')"  >¿Tienes Dudas?</li>
                    </ul>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <ul class="cu-ul">
                        <li class="cu-li" (click)="goTo('goobten')"  >Obtén Ok Boleta</li>
                        <li class="cu-li" (click)="goTo('gocontact')"  >Contácto</li>
                        <li class="cu-li" [routerLink]="['/privacy']"  >Políticas de privacidad</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12" >
            <div class="img-foike" id="foikelogo">
                <a>
                    <img src="assets/img/foikesoft.png" alt="">
                </a>
            </div>

        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <p class="cu-redes">Redes</p>
                    <ul class="list-group list-group-horizontal cu-ul-ico">
                        <li class="cu-li-ico"><img class="cu-ico" src="assets/img/wa.png" (click)="goToLink('https://wa.me/c/56999137004')" alt=""></li>
                        <li class="cu-li-ico"><img class="cu-ico" src="assets/img/fb.png" (click)="goToLink('https://www.facebook.com/okboleta')" alt=""></li>
                      </ul>
                </div>
                <br>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <p class="cu-redes">Diseñado por</p>
                    <div class="img-think">
                        <a>
                            <img src="assets/img/think.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</footer>


<ng-template #loading>
    <!-- modal confrimacion de eliminar -->
    <!-- <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Enviando</h5>
    </div> -->
    <div class="modal-body text-center">
        <h5 class="modal-title" id="exampleModalLabel">Enviando</h5>
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Enviando...</span>
          </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn button-cancel" (click)="onIrProductos()">Ir a productos</button>
      <button type="button" class="btn button-aceptar" (click)="onOK()">Aceptar</button>
    </div> -->
  </ng-template>
