
<!-- NAV -->
<nav id="mynav" class="navbar navbar-expand-xl fixed-top navbar-light cu-nav cu-nav-color" >
    <a class="navbar-brand cu-nav-a " style="cursor: pointer;" [routerLink]="['/']"  >
        <img class="cu-logo" src="assets/img/nav-logo.png"  alt="">
    </a>
    
</nav>
<p class="cu-inicio-margin" id="gocontact"></p>

<section class="cu-inicio" id="obten">
    <div class="row" style="width: 100%;">
        <div class="col-12">
            <div class="row cu-row-obten">
                <div class="col text-center">
                    <p class="title-purp"> Política de privacidad</p>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="row cu-row-obten">
                <div class="col text-center">
                    <div class="container">
                        <p class="text-dudas"> Los productos de Ok Boleta (colectivamente «Ok Boleta» en este documento) se refieren a las aplicaciones Ok Boleta, Ok Boleta T1N, Ok Boleta Q2-Q2S y okboleta.cl. Esta política de privacidad describe cómo Ok Boleta utiliza y protege cualquier información que nos proporcionas. Nos comprometemos a garantizar la protección de tu privacidad. Ok Boleta no almacena información sensible, y la información que se almacena, puedes estar seguro de que sólo se utilizará de acuerdo con esta declaración de privacidad.</p>
                        <br>
                        <p class="text-dudas-title"> Conservación de información</p>
                        <p class="text-dudas"> No conservamos tu información sensible en nuestros sistemas, solo de manera local en tu dispositivo con el fin de inicio de sesión automatizado. Guardamos información que nos ayude a brindar un mejor servicio, por ejemplo, entregar un resumen diario y mensual.</p>
                        <br>
                        <p class="text-dudas-title">Enlaces de terceros</p>
                        <p class="text-dudas">Ok Boleta puede contener enlaces a otras webs o apps ofrecidos por terceros que no están bajo nuestro control. Al seguir un enlace y facilitar información a la web o app de un tercero, por favor, sé consciente de que nosotros no somos responsables de los datos ofrecidos a ese tercero. Esta política de privacidad solo se aplica a los productos de la lista del principio de este documento, así que cuando visites otras webs o apps, aunque hagas clic en un enlace publicado en Ok Boleta, deberías leer sus propias políticas de privacidad.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>