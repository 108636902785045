import { Injectable, resolveForwardRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Credentials } from '../models/credentials';
import { Report } from '../models/Report';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class SiiService {

  constructor() { }

  login(rut: string, dv: string, clave: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const url = `${environment.urlLogin}/v1/signin`;

      axios.post(url, { rut, dv, clave })
        .then((res: any) => {
          const credentials: Credentials = res.data;
          resolve(credentials);
        })
        .catch((error: any) => {
          let credentials: Credentials;
          credentials.error = error
          resolve(credentials);
        });
    });

    return promise;
  }

  report(rut: string): Promise<any> {
    const url = environment.urlReport;
    const date = new Date().toLocaleDateString().split('-');
    const query = `contribuyente=${rut}
    &filtros=%5B%5D
    &pagination=%7B%22page%22%3A1%2C%22itemsPerPage%22%3A10%2C%22sortBy%22%3A%5B%5D%2C%22sortDesc%22%3A%5B%5D%2C%22groupBy%22%3A%5B%5D%2C%22groupDesc%22%3A%5B%5D%2C%22mustSort%22%3Afalse%2C%22multiSort%22%3Afalse%7D
    &dateRange=%7B%22from%22%3A%22${date[2]}-${date[1]}-${date[0]}%22%2C%22to%22%3A%22${date[2]}-${date[1]}-${date[0]}%22%7D`;
    const promise = new Promise((resolve, reject) => {
      axios.get(url + query).then((res) => {
        const report: Report = res.data;
        resolve(report);
      }).catch((error) => {
        let report: Report;
        report.error = error
        resolve(report);
      });
    });
    return promise;
  }
}
