import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/emision/login/login.component';
import { EmitirComponent } from './components/emision/emitir/emitir.component';
import { HomeComponent } from './components/home-pages/home/home.component';
import { PrivacyPolComponent } from './privacy-pol/privacy-pol.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  //{ path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  //{ path: 'login', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'emitir', component: EmitirComponent },
  { path: 'privacy', component: PrivacyPolComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
