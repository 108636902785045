// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBq3rQj3OtF0nzUu7jZdha509OT6AjGpjU",
    authDomain: "okboleta.firebaseapp.com",
    projectId: "okboleta",
    storageBucket: "okboleta.appspot.com",
    messagingSenderId: "560913416038",
    appId: "1:560913416038:web:95cb2e51c72a10be9d52a7",
    measurementId: "G-4HTTPXJBYQ"
  },
  urlLogin: 'https://us-central1-okboleta.cloudfunctions.net/api',
  urlReport: 'https://q0pwrt48l5.execute-api.us-east-1.amazonaws.com/prod/consultar-reportes?'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
