import { User } from '../models/user';
import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private tablaUser = 'users';

  constructor(public afStore: AngularFirestore, public ngFireAuth: AngularFireAuth, public router: Router, public ngZone: NgZone) {

    // this.ngFireAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user;
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     console.log('User:', JSON.stringify(this.userData));
    //     JSON.parse(localStorage.getItem('user'));
    //   } else {
    //     // console.log('User:', null);
    //     // localStorage.setItem('user', null);
    //     // JSON.parse(localStorage.getItem('user'));
    //   }
    // });
  }

  getUsers(): Observable<User[]> {
    return this.afStore.collection<User>(this.tablaUser).valueChanges();
  }

  getUser(rut: string): Promise<User> {
    try {
      const a = this.afStore.collection(this.tablaUser).doc<User>(rut).valueChanges();
      return new Promise((resolve, reject) => {
        a.subscribe((response: any) => {
          resolve(response);
        }, reject);
      });
    } catch (error) {
      throw error;
    }
  }

  getDateTime(): string {
    return `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
  }

  getRandomID() {
    return this.afStore.createId();
  }

  // --> Segregacion <--
  // GetAllSegregacionesFilterByUserEmail(userEmail: String): Observable<SegregacionInterface[]> {
  //   return this.afStore.collection<SegregacionInterface>(this.tablaUser, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // async UpdateSegregaciones(segregacion: SegregacionInterface): Promise<void> {
  //   try {
  //     return this.afStore.collection(this.tablaSegregacion).doc<SegregacionInterface>(segregacion.id).set(segregacion);
  //     // await this.afStore.collection<SegregacionInterface>(this.tablaSegregacion, ref => ref.where('planta', '==', segregacion.planta)).update(segregacion);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // --> Variedades <--
  // GetAllVariedades(): Observable<any> {
  //   return this.afStore.collection<any>(this.tableVariedad).valueChanges();
  // }
  // async NuevaVariedad(variedad: any) {
  //   try {
  //     const id = this.afStore.createId();
  //     variedad.id = id;
  //     await this.afStore.collection(this.tableVariedad).doc(id).set(variedad);
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  // async DeleteVariedad(v: any) {
  //   try {
  //     await this.afStore.collection(this.tableVariedad).doc(v.id).delete();
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  // --> Especies <--
  // GetAllEspecies(): Observable<any> {
  //   return this.afStore.collection<any>(this.tableEspecie).valueChanges();
  // }
  // async NuevaEspecie(especie: any) {
  //   try {
  //     const id = this.afStore.createId();
  //     especie.id = id;
  //     // info adicional
  //     especie.creador = this.userData.email;
  //     especie.creadaEl = this.getDateTime();
  //     await this.afStore.collection(this.tableEspecie).doc(id).set(especie);
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  // async DeleteEspecie(v: any) {
  //   try {
  //     await this.afStore.collection(this.tableEspecie).doc(v.id).delete();
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // --> Exportadoras <--
  // GetAllExportadoras(): Observable<ExportadoraInterface[]> {
  //   // Método 1
  //   return this.afStore.collection<ExportadoraInterface>(this.tablaExportadora).valueChanges();
  // }

  // GetAllExportadorasFilterByUserEmail(userEmail: String): Observable<ExportadoraInterface[]> {
  //   return this.afStore.collection<ExportadoraInterface>(this.tablaExportadora, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetAllCalibresFilterByUserEmail(userEmail: String): Observable<CalibreInterface[]> {
  //   return this.afStore.collection<CalibreInterface>(this.tablaCalibre, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // async AddNewExportadora(exportadora: ExportadoraInterface) {
  //   try {
  //     const id = this.afStore.createId();
  //     exportadora.id = id;
  //     exportadora.planta = this.userData.email;
  //     await this.afStore.collection(this.tablaExportadora).doc(id).set(exportadora);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async addNewCalibre(calibre: CalibreInterface) {
  //   try {
  //     const id = this.afStore.createId();
  //     calibre.id = id;
  //     calibre.planta = this.userData.email;
  //     await this.afStore.collection(this.tablaCalibre).doc(id).set(calibre);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // GetOneExportadora(idExportadora: string): Observable<ExportadoraInterface> {
  //   return this.afStore.collection(this.tablaExportadora).doc<ExportadoraInterface>(idExportadora).valueChanges();
  // }

  // async UpdateExportadora(e: ExportadoraInterface) {
  //   try {
  //     await this.afStore.collection(this.tablaExportadora).doc<ExportadoraInterface>(e.id).update(e);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async DeleteExportadora(e: ExportadoraInterface) {
  //   try {
  //     await this.afStore.collection(this.tablaExportadora).doc(e.id).delete();
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async DeleteCalibre(e: CalibreInterface) {
  //   try {
  //     await this.afStore.collection(this.tablaCalibre).doc(e.id).delete();
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // GetAllFirmezas(): Observable<FirmezaInterface[]> {
  //   // Método 1
  //   return this.afStore.collection<FirmezaInterface>(this.tableFirmeza).valueChanges();
  // }

  // GetFirmezaFilterByUserEmail(userEmail: String): Observable<FirmezaInterface[]> {
  //   return this.afStore.collection<FirmezaInterface>(this.tableFirmeza, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetCamarasEsperaProcesoFilterByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tableCamara, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetCamarasMantencionFilterByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tableCamaraMantencion, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetTunelesPrefrioFilterByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tableTuneles, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetDetallePalletFilterByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tableDetallePallet, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetColorFilterByUserEmail(userEmail: String): Observable<ColorInterface[]> {
  //   return this.afStore.collection<ColorInterface>(this.tablaColor, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetDefectosCalidadFilterByUserEmail(userEmail: String): Observable<DefectosCalidadInterface[]> {
  //   return this.afStore.collection<DefectosCalidadInterface>(this.tablaDefectosCalidad, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetDefectosCondicionFilterByUserEmail(userEmail: String): Observable<DefectosCalidadInterface[]> {
  //   return this.afStore.collection<DefectosCalidadInterface>(this.tablaDefectosCondicion, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetImportadorRecibidorByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaImportadorRecibidor, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetCalificacionProcesoByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaCalificacionProceso, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetPuertoCargaByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaPuertoCarga, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetPuertoDestinoByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaPuertoDestino, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetTipoCargaByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaTipoCarga, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetLugaresByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaLugares, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetLugaresTempByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaLugaresTemp, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetImgsConsolidacionByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaImagenesTipo, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetSelladorasByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaSelladoras, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetLineasByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaLineas, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }
  // GetAppProductosByUserEmail(userEmail: String): Observable<any[]> {
  //   return this.afStore.collection<any>(this.tablaAppProductos, ref => ref.where('planta', '==', userEmail)).valueChanges();
  // }

  // GetAllRecepciones(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaRecepcion).doc(userEmail).collection(this.tablaRecepciones).get();
  // }

  // GetAllProcesos(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaProceso).doc(userEmail).collection(this.tablaProcesos).get();
  // }

  // GetAllHidrocooler(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaFrigorifico).doc(userEmail).collection(this.tablaHidrocooler).get();
  // }

  // GetAllEsperaProceso(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaFrigorifico).doc(userEmail).collection(this.tablaEsperaProceso).get();
  // }

  // GetAllPallet(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaFrigorifico).doc(userEmail).collection(this.tablaPallet).get();
  // }

  // GetPrefriosOfPallet(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaFrigorifico).doc(userEmail).collection(this.tablaPrefriosOfPallets).get();
  // }

  // GetCamarasOfPallet(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaFrigorifico).doc(userEmail).collection(this.tablaCamarasOfPallets).get();
  // }

  // GetAllConsolidacion(userEmail: string): Observable<any> {
  //   return this.afStore.collection<any>(this.tablaConsolidacion).doc(userEmail).collection(this.tablaOrdenes).get();
  // }

  // async DeleteFirmeza(e: FirmezaInterface) {
  //   try {
  //     if (e.tipos.length == 0) {
  //       await this.afStore.collection(this.tableFirmeza).doc(e.id).delete();
  //     } else {
  //       await this.afStore.collection(this.tableFirmeza).doc(e.id).set(e);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async DeleteColor(e: ColorInterface, color: string) {
  //   try {
  //     if (e.tipos.length == 0) {
  //       await this.afStore.collection(this.tablaColor).doc(e.id).delete();
  //     } else {
  //       await this.afStore.collection(this.tablaColor).doc(e.id).set(e);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async DeleteDefectosCalidad(e: DefectosCalidadInterface, color: string) {
  //   try {
  //     if (e.tipos.length == 0) {
  //       await this.afStore.collection(this.tablaDefectosCalidad).doc(e.id).delete();
  //     } else {
  //       await this.afStore.collection(this.tablaDefectosCalidad).doc(e.id).set(e);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async DeleteDefectosCondicion(e: DefectosCondicionInterface, color: string) {
  //   try {
  //     if (e.tipos.length == 0) {
  //       await this.afStore.collection(this.tablaDefectosCondicion).doc(e.id).delete();
  //     } else {
  //       await this.afStore.collection(this.tablaDefectosCondicion).doc(e.id).set(e);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // Login in with email/password
  // SignIn(email, password) {
  //   return this.ngFireAuth.auth.signInWithEmailAndPassword(email, password);
  // }

  // CreateUser(email, password) {
  //   return this.ngFireAuth.auth.createUserWithEmailAndPassword(email, password);
  // }

  // getCurrentUser() {
  //   return JSON.parse(localStorage.getItem('user'));
  // }

  // SingOut() {
  //   return this.ngFireAuth.auth.signOut();
  // }

  // Returns true when user is looged in
  // isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   console.log('isLoggedIn:', user);
  //   return (user !== null) ? true : false;
  // }


  // async UpdateFirmeza(firmeza: FirmezaInterface) {
  //   try {
  //     await this.afStore.collection(this.tableFirmeza).doc(firmeza.id).set(firmeza);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateCamaraEsperaProceso(data) {
  //   try {
  //     await this.afStore.collection(this.tableCamara).doc(data.id).set(data);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateCamaraMantencion(data) {
  //   try {
  //     await this.afStore.collection(this.tableCamaraMantencion).doc(data.id).set(data);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateTunelesPrefrio(data) {
  //   try {
  //     await this.afStore.collection(this.tableTuneles).doc(data.id).set(data);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateDetallePallet(data) {
  //   try {
  //     await this.afStore.collection(this.tableDetallePallet).doc(data.id).set(data);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateCalibre(calibre: CalibreInterface) {
  //   try {
  //     await this.afStore.collection(this.tablaCalibre).doc(calibre.id).set(calibre);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateColor(color: ColorInterface) {
  //   try {
  //     await this.afStore.collection(this.tablaColor).doc(color.id).set(color);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateDefectosCalidad(defectosCalidad: DefectosCalidadInterface) {
  //   try {
  //     await this.afStore.collection(this.tablaDefectosCalidad).doc(defectosCalidad.id).set(defectosCalidad);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateDefectosCondicion(defectosCondicion: DefectosCondicionInterface) {
  //   try {
  //     await this.afStore.collection(this.tablaDefectosCondicion).doc(defectosCondicion.id).set(defectosCondicion);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateImportadorRecibidor(importadorRecibidor: any) {
  //   try {
  //     await this.afStore.collection(this.tablaImportadorRecibidor).doc(importadorRecibidor.id).set(importadorRecibidor);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateCalificacionProceso(calificacion: any) {
  //   try {
  //     const resp = await this.afStore.collection(this.tablaCalificacionProceso).doc(calificacion.id).set(calificacion);
  //     console.log(resp);
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // }

  // async UpdatePuertoCarga(puertoCarga: any) {
  //   try {
  //     await this.afStore.collection(this.tablaPuertoCarga).doc(puertoCarga.id).set(puertoCarga);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdatePuertoDestino(puertoDestino: any) {
  //   try {
  //     await this.afStore.collection(this.tablaPuertoDestino).doc(puertoDestino.id).set(puertoDestino);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateTipoCarga(tipoCarga: any) {
  //   try {
  //     await this.afStore.collection(this.tablaTipoCarga).doc(tipoCarga.id).set(tipoCarga);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateLugares(lugares: any) {
  //   try {
  //     await this.afStore.collection(this.tablaLugares).doc(lugares.id).set(lugares);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateLugaresTemp(lugares: any) {
  //   try {
  //     await this.afStore.collection(this.tablaLugaresTemp).doc(lugares.id).set(lugares);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateImgsConsolidacion(lugares: any) {
  //   try {
  //     await this.afStore.collection(this.tablaImagenesTipo).doc(lugares.id).set(lugares);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateSelladoras(selladoras: any) {
  //   try {
  //     await this.afStore.collection(this.tablaSelladoras).doc(selladoras.id).set(selladoras);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateLineas(linea: any) {
  //   try {
  //     await this.afStore.collection(this.tablaLineas).doc(linea.id).set(linea);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async UpdateAppProductos(productos: any) {
  //   try {
  //     await this.afStore.collection(this.tablaAppProductos).doc(productos.id).set(productos);
  //   } catch (error) {
  //     throw error;
  //   }
  // }




}
