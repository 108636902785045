
<div >
    <div style="position: relative; top: 0; left: 0; max-width: 100%; height: 150px;">
        <img src="assets/img/ok_emitir.png" style="display: flex; margin: auto; z-index: 3; position: absolute; left: 50%; transform: translateX(-50%);" alt="">
        <img src="assets/img/ok_emitir_line.png" style="position: relative; top: 0; left: 0; z-index: 1; width: 100%;" alt="">
        
    </div>

    <div class="box-view">
        <div class="box-border">
            <div class="row  ml-1 mr-1 mb-3">
                <div class="col col-12 mt-2">
                    <label class="casilla-monto" for="">{{monto | currency : '$': 'symbol' : '1.0-0'}}</label>
                </div>
            </div>

            

            <div class="row ml-1 mr-1 mb-3">
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('7')">7</button>
                </div>
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('8')">8</button>
                </div>
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('9')">9</button>
                </div>
            </div>

            <div class="row  ml-1 mr-1 mb-3">
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('4')">4</button>
                </div>
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('5')">5</button>
                </div>
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('6')">6</button>
                </div>
            </div>

            <div class="row  ml-1 mr-1 mb-3">
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('1')">1</button>
                </div>
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('2')">2</button>
                </div>
                <div class="col col-4 text-center">
                    <button class="btn-number" (click)="onClickNum('3')">3</button>
                </div>
            </div>

            <div class="row  ml-1 mr-1 mb-3">
                <div class="col col-12 text-center">
                    <button class="btn-number-0" (click)="onClickNum('0')">0</button>
                </div>
            </div>

            
            <div class="row  ml-1 mr-1 mb-3">
                <!-- BTN RESET -->
                <div class="col col-6 text-center">
                    <button class="btn-reset" (click)="onClickReset()">X</button>
                </div>
                <!-- BTN BORRAR -->
                <div class="col col-6 text-center">
                    <button class="btn-borrar" (click)="onClickBorrar()"><img src="assets/img/left-arrow.svg" style="max-width: 60px;" alt=""></button>
                </div>
            </div>

            <!-- BTN OK -->
            <div class="row  ml-1 mr-1 mb-3 ">
                <div class="col col-12 text-center">
                    <button class="btn-ok">ok</button>
                </div>
            </div>

        </div>
    </div>
</div>