import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, MinLengthValidator, Validators } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
  eleNav: HTMLElement;
  eleOff: HTMLElement;

  secInicio: HTMLElement;
  secUso: HTMLElement;
  secBeneficio: HTMLElement;
  secDudas: HTMLElement;
  secObten: HTMLElement;
  secContacto: HTMLElement;

  aUso: HTMLElement;
  liUso: HTMLElement;

  aBeneficio: HTMLElement;
  liBeneficio: HTMLElement;

  aDudas: HTMLElement;
  liDudas: HTMLElement;

  aObten: HTMLElement;
  liObten: HTMLElement;

  aContacto: HTMLElement;
  liContacto: HTMLElement;

  //inputs
  boolNombre = false;

  // disabled
  btnEnviar = true;

  contactoForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    telefono: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]),
    message: new FormControl('', Validators.required)
  });


  constructor( private httpClient: HttpClient) { }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true); // third parameter
    this.eleNav = document.getElementById('mynav');
    this.eleOff = document.getElementById('inicioTop');

    this.secInicio = document.getElementById('inicio');
    this.secUso = document.getElementById('uso');
    this.secBeneficio = document.getElementById('beneficios');
    this.secDudas = document.getElementById('dudas');
    this.secObten = document.getElementById('obten');
    this.secContacto = document.getElementById('contacto');

    this.aUso = document.getElementById('auso');
    this.liUso = document.getElementById('liuso');

    this.aBeneficio = document.getElementById('abeneficio');
    this.liBeneficio = document.getElementById('libeneficio');

    this.aDudas = document.getElementById('adudas');
    this.liDudas = document.getElementById('lidudas');

    this.aObten = document.getElementById('aobten');
    this.liObten = document.getElementById('liobten');

    this.aContacto = document.getElementById('acontacto');
    this.liContacto = document.getElementById('licontacto');
  }



  scroll = (event: any): void => {
    // Here scroll is a variable holding the anonymous function
    // this allows scroll to be assigned to the event during onInit
    // and removed onDestroy
    // To see what changed:



    if (window.pageYOffset >= this.secInicio.scrollHeight) {

      // eleNav.classList.remove('cu-nav');
      this.eleNav.classList.add('cu-nav-color');
    } else {

      this.eleNav.classList.remove('cu-nav-color');
      // eleNav.classList.add('cu-nav');
    }




    // SECCIONES

    // USO
    if (window.pageYOffset > this.secInicio.scrollHeight -1 &&
      window.pageYOffset < this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight) {
      if (!this.liUso.classList.contains('cu-item-backcolor')) {
        this.liUso.classList.add('cu-item-backcolor');
      }

      if (!this.aUso.classList.contains('color-nav-1')) {
        this.aUso.classList.add('color-nav-1');
      }
      if (this.aUso.classList.contains('color-nav-2')) {
        this.aUso.classList.remove('color-nav-2');
      }
    } else {
      if (this.liUso.classList.contains('cu-item-backcolor')) {
        this.liUso.classList.remove('cu-item-backcolor');
      }
      if (window.pageYOffset < this.secInicio.scrollHeight) {
        if (!this.aUso.classList.contains('color-nav-1')) {
          this.aUso.classList.add('color-nav-1');
        }
        if (this.aUso.classList.contains('color-nav-2')) {
          this.aUso.classList.remove('color-nav-2');
        }
      } else {
        if (!this.aUso.classList.contains('color-nav-2')) {
          this.aUso.classList.add('color-nav-2');
        }
        if (this.aUso.classList.contains('color-nav-1')) {
          this.aUso.classList.remove('color-nav-1');
        }
      }
    }

    // Beneficio
    if (window.pageYOffset > this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight &&
      window.pageYOffset < this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight +this.secBeneficio.scrollHeight + this.eleOff.scrollHeight) {
      if (!this.liBeneficio.classList.contains('cu-item-backcolor')) {
        this.liBeneficio.classList.add('cu-item-backcolor');
      }

      if (!this.aBeneficio.classList.contains('color-nav-1')) {
        this.aBeneficio.classList.add('color-nav-1');
      }
      if (this.aBeneficio.classList.contains('color-nav-2')) {
        this.aBeneficio.classList.remove('color-nav-2');
      }
    } else {
      if (this.liBeneficio.classList.contains('cu-item-backcolor')) {
        this.liBeneficio.classList.remove('cu-item-backcolor');
      }
      if (window.pageYOffset < this.secInicio.scrollHeight) {
        if (!this.aBeneficio.classList.contains('color-nav-1')) {
          this.aBeneficio.classList.add('color-nav-1');
        }
        if (this.aBeneficio.classList.contains('color-nav-2')) {
          this.aBeneficio.classList.remove('color-nav-2');
        }
      } else {
        if (!this.aBeneficio.classList.contains('color-nav-2')) {
          this.aBeneficio.classList.add('color-nav-2');
        }
        if (this.aBeneficio.classList.contains('color-nav-1')) {
          this.aBeneficio.classList.remove('color-nav-1');
        }
      }
    }


    // dudas
    if (window.pageYOffset > this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight 
      +this.secBeneficio.scrollHeight + this.eleOff.scrollHeight &&
      window.pageYOffset < this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight 
      +this.secBeneficio.scrollHeight + this.eleOff.scrollHeight + this.secDudas.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight ) {
      if (!this.liDudas.classList.contains('cu-item-backcolor')) {
        this.liDudas.classList.add('cu-item-backcolor');
      }

      if (!this.aDudas.classList.contains('color-nav-1')) {
        this.aDudas.classList.add('color-nav-1');
      }
      if (this.aDudas.classList.contains('color-nav-2')) {
        this.aDudas.classList.remove('color-nav-2');
      }
    } else {
      if (this.liDudas.classList.contains('cu-item-backcolor')) {
        this.liDudas.classList.remove('cu-item-backcolor');
      }
      if (window.pageYOffset < this.secInicio.scrollHeight) {
        if (!this.aDudas.classList.contains('color-nav-1')) {
          this.aDudas.classList.add('color-nav-1');
        }
        if (this.aDudas.classList.contains('color-nav-2')) {
          this.aDudas.classList.remove('color-nav-2');
        }
      } else {
        if (!this.aDudas.classList.contains('color-nav-2')) {
          this.aDudas.classList.add('color-nav-2');
        }
        if (this.aDudas.classList.contains('color-nav-1')) {
          this.aDudas.classList.remove('color-nav-1');
        }
      }
    }


    // OBTEN
    if (window.pageYOffset > this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight 
      +this.secBeneficio.scrollHeight + this.eleOff.scrollHeight + this.secDudas.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight &&
      window.pageYOffset < this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight 
      +this.secBeneficio.scrollHeight + this.eleOff.scrollHeight + this.secDudas.scrollHeight + this.eleOff.scrollHeight + this.secObten.scrollHeight +  this.eleOff.scrollHeight ) {
      if (!this.liObten.classList.contains('cu-item-backcolor')) {
        this.liObten.classList.add('cu-item-backcolor');
      }

      if (!this.aObten.classList.contains('color-nav-1')) {
        this.aObten.classList.add('color-nav-1');
      }
      if (this.aObten.classList.contains('color-nav-2')) {
        this.aObten.classList.remove('color-nav-2');
      }
    } else {
      if (this.liObten.classList.contains('cu-item-backcolor')) {
        this.liObten.classList.remove('cu-item-backcolor');
      }
      if (window.pageYOffset < this.secInicio.scrollHeight) {
        if (!this.aObten.classList.contains('color-nav-1')) {
          this.aObten.classList.add('color-nav-1');
        }
        if (this.aObten.classList.contains('color-nav-2')) {
          this.aObten.classList.remove('color-nav-2');
        }
      } else {
        if (!this.aObten.classList.contains('color-nav-2')) {
          this.aObten.classList.add('color-nav-2');
        }
        if (this.aObten.classList.contains('color-nav-1')) {
          this.aObten.classList.remove('color-nav-1');
        }
      }
    }


    // Contacto
    if (window.pageYOffset > this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight 
      +this.secBeneficio.scrollHeight + this.eleOff.scrollHeight + this.secDudas.scrollHeight + this.eleOff.scrollHeight + this.secObten.scrollHeight +  this.eleOff.scrollHeight &&
      window.pageYOffset < this.secInicio.scrollHeight + this.secUso.scrollHeight + this.eleOff.scrollHeight + this.eleOff.scrollHeight 
      +this.secBeneficio.scrollHeight + this.eleOff.scrollHeight + this.secDudas.scrollHeight + this.eleOff.scrollHeight + this.secObten.scrollHeight + 
       this.eleOff.scrollHeight + this.secContacto.scrollHeight +  this.eleOff.scrollHeight ) {
      if (!this.liContacto.classList.contains('cu-item-backcolor')) {
        this.liContacto.classList.add('cu-item-backcolor');
      }

      if (!this.aContacto.classList.contains('color-nav-1')) {
        this.aContacto.classList.add('color-nav-1');
      }
      if (this.aContacto.classList.contains('color-nav-2')) {
        this.aContacto.classList.remove('color-nav-2');
      }
    } else {
      if (this.liContacto.classList.contains('cu-item-backcolor')) {
        this.liContacto.classList.remove('cu-item-backcolor');
      }
      if (window.pageYOffset < this.secInicio.scrollHeight) {
        if (!this.aContacto.classList.contains('color-nav-1')) {
          this.aContacto.classList.add('color-nav-1');
        }
        if (this.aContacto.classList.contains('color-nav-2')) {
          this.aContacto.classList.remove('color-nav-2');
        }
      } else {
        if (!this.aContacto.classList.contains('color-nav-2')) {
          this.aContacto.classList.add('color-nav-2');
        }
        if (this.aContacto.classList.contains('color-nav-1')) {
          this.aContacto.classList.remove('color-nav-1');
        }
      }
    }

  }

  goTo(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior: "smooth", block: "start"});
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  async onSubmit(customerData, modal) {
    if (this.contactoForm.invalid) {
      console.log("INVALID FORM");
      if (this.contactoForm.get("nombre").invalid) {
        this.boolNombre = true;
        console.log("nombre invalido");
      }

      if (this.contactoForm.get("email").invalid) {
        console.log("email invalido");
      }

      if (this.contactoForm.get("telefono").invalid) {
        console.log("telefono invalido");
      }

      if (this.contactoForm.get("message").invalid) {
        console.log("message invalido");
      }
      return;
      
    }
    console.log("ok FORM");
    //this.modalService.open(modal, { windowClass: 'w3-animate-top' });
    try {
      //this.btnEnviar = true;
      /* const resp: any = await this.httpClient.post('https://us-central1-eco-bomba-solar.cloudfunctions.net/mailer', {
        email: this.contactoForm.get('email').value,
        message: this.contactoForm.get('message').value,
        telefono: this.contactoForm.get('telefono').value,
        nombre: this.contactoForm.get('nombre').value,
        code: 'frontToBackend'
      }).toPromise(); */
      /* if (!!resp) {
        this.contactoForm.reset();

      } */
    } catch (error) {
      console.log('error', error);
    }
    //this.modalService.dismissAll();
  }

}
