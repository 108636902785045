import { Component, OnInit } from '@angular/core';
import { Credentials } from '../../../models/credentials';
import { Report } from '../../../models/report';
import { FirebaseService } from '../../../services/firebase.service';
import { SiiService } from '../../../services/sii.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private firebaseService: FirebaseService, private siiService: SiiService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.firebaseService.getUsers().subscribe(res => {
      // console.log(res);
    });

    //Consulta si existe en la base de datos
    const user = await this.firebaseService.getUser("8201936");

    if (user) {
      //Autenticar contra SII
      //Se omite la autenticación contra el sii, ya que no es necesario
      // const credentials: Credentials = await this.siiService.login("8201936", "7", "HOLAMUNDO");
      const credentials: Credentials = { accessKeyId: "", secretAccessKey: "", sessionToken: "" };
      if (credentials.error) {
        //Manejo en caso de error
        console.log("Error al obtener credenciales", credentials.error);
      } else {
        //Usuario autenticado
        console.log("Obtencion de credenciales OK", credentials);


        //Obtener reporte 
        const report: Report = await this.siiService.report("8201936");
        if (report.error) {
          //Manejo en caso de error
          console.log("Error al obtener reporte", report.error);
        } else {
          //Report obtenido
          console.log("Obtencion de reporte OK", report);
        }

      }
    } else {
      //Usuario no utiliza okboleta
      console.log("No estás autorizado");
    }



    // this.firebaseService.getUser("8201936").subscribe(res => {
    //   console.log("user", res);

    // });

  }

  goToEmitir() {
    this.router.navigateByUrl("/emitir")
  }

}
