import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emitir',
  templateUrl: './emitir.component.html',
  styleUrls: ['./emitir.component.css']
})
export class EmitirComponent implements OnInit {
  monto: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  onClickNum(num: string) {
    console.log();
    
    if (num === '0' && this.monto.length !== 0) {
      console.log("agrego 1");
      
      this.monto += num;
    } else if (num !== '0') {
      console.log("agrego 2");
      this.monto += num;
    } else {
      //do nothing
    }

    
    
  }

  onClickBorrar() {
    if (this.monto.length > 1) {
      this.monto = this.monto.substring(0, this.monto.length - 1);
    } else {
      this.monto = '';
    }
  }

  onClickReset() {
    this.monto = '';
  }

}
